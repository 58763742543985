import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
			ArtPixel
			</title>
			<meta name={"description"} content={"Творите смело, воплощайте смелее – ArtPixel, ваш путь в мир графического дизайна"} />
			<meta property={"og:title"} content={"ArtPixel"} />
			<meta property={"og:description"} content={"Творите смело, воплощайте смелее – ArtPixel, ваш путь в мир графического дизайна"} />
			<meta property={"og:image"} content={"https://tovlyswift.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://tovlyswift.com/img/icon1.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://tovlyswift.com/img/icon1.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://tovlyswift.com/img/icon1.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://tovlyswift.com/img/icon1.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://tovlyswift.com/img/icon1.png"} />
			<meta name={"msapplication-TileImage"} content={"https://tovlyswift.com/img/icon1.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 30px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					О Нас
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://tovlyswift.com/img/4.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="356px"
						width="100%"
						object-fit="none"
					/>
				</Box>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
						Наша История
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							ArtPixel зародился из стремления сделать мир графического дизайна доступным каждому. Мы создали платформу, где ученики могут не только изучать основы и продвинутые техники дизайна, но и применять их, создавая собственные шедевры. ArtPixel – это место, где творчество встречает технологии, а учебный процесс становится увлекательным путешествием.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
						Ваши Возможности с ArtPixel
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						В ArtPixel каждый учащийся – автор своей уникальной истории. Мы приглашаем вас в путешествие, где вы не только научитесь создавать визуальные шедевры, но и сможете воплотить в жизнь свои самые смелые идеи. С нами вы не просто учитесь – вы становитесь частью сообщества творцов, готовых изменить мир дизайна.
						</Text>
					</Box>
				</Box>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://tovlyswift.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="356px"
						width="100%"
						object-fit="none"
					/>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Наши Преимущества
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Доступное Обучение: Мы предлагаем курсы, которые удобны в изучении, независимо от вашего уровня подготовки.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Практический Опыт: Учащиеся работают над реальными проектами, которые помогут им построить портфолио.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Инновационные Инструменты: Мы используем последние инновации в области обучения и дизайна, чтобы сделать каждый курс максимально эффективным.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});